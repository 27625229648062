body{ 
    .timesheet-con{
        
        .attendance {
            margin-bottom: 25px;
            .text-container {
                background-color: #F4F4F4;
                border-radius: 20px;
                padding: 20px;
                margin-top: 25px;
                .time {
                    display: flex;
                    justify-content: space-between;
                    .desc{
                        color: #0093DD;
                        font-size: 16px;
                        font-weight: 700;
                    }
                }
                .time2{
                    display: flex;
                    justify-content: space-between;
                    
                }
                .location{
                    display: flex; 
                    gap: 5px;
                }
                .text{
                    font-size: 16px;
                    margin-bottom: 15px;
                    color: #000 !important;
                }
                .plan-con{
                    margin-top: 15px;
                    .plan {
                        display: flex;
                        justify-content: space-around;
                        margin-bottom: 20px;
                        .plan-input {
                          label{
                            display: block;
                          }
                            .plan-box{
                                background-color: #0093DD;
                                border: none;
                                border-radius: 8px;
                                padding: 5px;
                                color: #fff;
                                font-size: 14px;
                                font-weight: 700;
                                text-align: center;
                                width: 100px !important;
                            }
                        }
                    }
                }
            }
        }
        .manager-review {
            border-radius: 20px;
            padding: 20px;
            margin-top: -10px;
            background-color: #F4F4F4;
            .radio{
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                .checkbox-label {
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }
    }
}
  .checkbox {
    display: flex;
  }
  
  .checkbox-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  
  .label {
    margin-left: 10px;
    font-size: 18px;
  }
  
  .even-label, .odd-label {
    font-size: 12px;
    font-weight: 700;
    margin-right: 4px;
  }
  
  .radio-button-container label {
    display: inline-block;
    margin-right: 10px;
  }
  
  .radio-button-container input[type="radio"] {
    appearance: checkbox; /* This makes radios look like checkboxes */
    -moz-appearance: checkbox; /* Firefox */
    -webkit-appearance: checkbox; /* WebKit/Blink */
  }
  .sigCanvas {
    border: 1px solid #B7B7B7;
    border-radius: 5px;
    width: 100%;
    height: 100px;
    background-color: #fff;
  }
  .signature-container{
    display: flex;
    gap: 10px;
  }
  
  .clear-button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #0093DD;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .clear-button:hover {
    background-color: #007bb5;
  }