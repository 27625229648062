.submitted-sheet-container {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.submitted-sheet {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  h3 {
    text-align: center;
    color: #3a3a3a;
  }
}

strong{
  color: #0093dd;
}


.sheet-details {
  margin-top: 20px;
}
.col-con{
  display: flex;
  justify-content: space-between;
}
.column {
  margin-bottom: 15px;
}

.column p {
  margin: 0;
}

.punctuality-section {
  background-color: #F4F4F4;
  margin-top: 20px;
  padding: 20px;
}
.attributes{
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.attributes span{
  font-weight: 700;
}
.sign{
  padding: 10px;
}
.sign span{
  font-weight: 700;
}
.sigcontainer{
  margin-top: 20px;
}

.signature-section {
  text-align: center;
}

.sigcontainer{
  background-color: #fff
}
.signature {
  width: 150px;
  height: auto;
  margin-top: 10px;
}

.submission-info {
  color: #0093dd;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  font-weight: 700;
}
