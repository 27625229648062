.dashboard{

    .timesheet{
        margin:0 15px;
        .textContainer{
            background-color: #0093DD;
            border-radius: 20px;
            padding: 0 20px 10px 20px;
            font-family: 'Nunito', sans-serif;
            .days{
            display: flex;
            justify-content: space-between;
            .number{
                color: #fff;
                font-size: 36px;
                font-weight: 800;
                margin: 0;
                margin-top: 20px;
            }
            }
            .text{
                color: #fff;
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 15px;
            }
            .month{
                color: #fff;
                font-weight: 700;
                font-size: 22px;
            }
        }
    }
    .container{
        margin: 0 15px;
        .record {
            border-radius: 20px;
            border:1px solid #0093DD;
            background-color: #fff;
            font-family: 'Roboto', sans-serif;
            padding-bottom: 20px;
            .title {
                font-weight: 700;
                font-size: 18px;
                padding-left: 20px;
                margin: 10px 0;
            }
            .light {
                background-color: #CEEFFF;
                padding: 15px 10px 10px 10px;
                margin-bottom: 5px;
                .time-info{
                    display: flex;
                    gap: 10px;
                    margin-bottom: 20px 15px;
                }
                .details{

                    .detail-item{
                        display: flex;
                        gap: 10px;
                        margin-bottom: 10px;
                             
                    }
                }
            }
            .text2{
                font-weight: 700;
                font-size: 14px;
                color: #3A3A3A;
                margin: 0;
                margin-bottom: 10px;
            }
            .break{
                width: 80%;
            }
            .link {
                color: #0093DD;
                text-decoration: none;
                margin: 20px ;
            }
        }
        
    }
}