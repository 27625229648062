.success {
    display: flex;
    flex-direction: column;
    background-color: #eceeec;
    padding: 20px;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .img {
    height: 200px;
  }
  
  .warning {
    color: #0093DD;
    font-weight: 600;
    font-size: 19px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .button {
    background-color: #0093DD;
    padding: 20px 30px;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 150px;
    border: none;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #007bbd; /* Optional: Darken the button color on hover */
  }
  